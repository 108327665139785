import React from "react"

const Page404 = () => {
  return (
    <div className="page-404">
      <h1>Ouch</h1>
      <p>La page demandée est introuvable</p>
      <a href="/">Retour à l'accueil</a>
    </div>
  )
}

export default Page404
